import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  TopToolbar,
  ListButton,
  RefreshButton,
  ImageField,
  ImageInput,
  FunctionField,
  BooleanField,
  DateField,
  BooleanInput,
  Toolbar,
  SelectInput,
  FileInput,
  useNotify,
  TabbedForm,
  FormTab,
  TextInput,
  Button,
  useRedirect,
  ArrayField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useRecordContext,
} from 'react-admin';
import styled from 'styled-components';
import { NumberInput } from '../../inputs/number-input';
import { DEFAULT_LOCALE, ErrorTypes, imageInputAccept, SortingDirection } from '../../variables';
import { getEnglishTitle } from './missions';
import { useEffect, useState } from 'react';
import { Add, RemoveCircle, Save } from '@mui/icons-material';
import { createManyPromoCodes, updateManyPromoCodes, deleteManyPromoCodes } from '../../api-service';
import { Fab, Input } from '@mui/material';
import { LotteryForm } from './lottery-form';
import { FcfsForm } from './fcfs-form';
import { QuestType, RewardsType } from './variables';
import { AdminSaveButton } from '../../buttons/save-btn';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { AdminCreateBtn } from '../../buttons/create-btn';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { FormType } from '../../variables';
import { getRole } from '../../utils/get-role';
import { UserRole } from '../../variables/user-role';
import { TinymceEditor } from '../../inputs/tinymce-editor';
import { TrimOnBlurInput } from '../../inputs/trim-on-blur-input';
import { helperTexts } from '../../variables/helper-texts';
import { TranslatableInput } from 'src/inputs/translatable-input';

const PromoCodeLine = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 5px 0;
`;

const PaginationWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
`;

const SubmitButton = styled(Button)`
  position: fixed !important;
  right: 50px;
  bottom: 15px;
`;

export enum LimitedOptions {
  LIMITED = 'Limited',
  UNLIMITED = 'Unlimited',
}

const gamificationQuestTypes = [
  { id: 'lottery', name: 'Lottery' },
  { id: 'firstComeFirstServed', name: 'FCFS' },
];

const PROMOCODES_PAGE_LENGTH = 30;

type PromoCode = {
  data: string;
  id?: string;
  edited?: boolean;
};

const isDisabledByRole = getIsDisabledByRole();

const WriteOffToolbar = (props) => {
  const isSecondTab = Boolean(window.location.href.match(/\/1$/));

  if (isSecondTab) {
    return null;
  }

  const redirect = (basePath, id, data) => {
    if (props.type === FormType.EDIT) {
      return basePath;
    }

    const nextTabIndex = 1;

    return basePath + '/' + id + '/' + nextTabIndex;
  };

  return (
    <Toolbar {...props}>
      <AdminSaveButton {...props} mutationOptions redirect={redirect} />
    </Toolbar>
  );
};

const PromoCodesTab = ({ basePath }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext();

  const writeOffId = record?.id as number | undefined;
  const isPromoCodesType = record.questType === gamificationQuestTypes[1].id;
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);
  const [newPromoCodeData, setNewPromoCodeData] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [deletedPromoCodeIds, setDeletedPromoCodeIds] = useState<string[]>([]);
  const isUnlimited = record.questData?.type === LimitedOptions.UNLIMITED && promoCodes.length === 1;

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (!record.promoCodes) {
      return;
    }

    if (!record.promoCodes.length) {
      setNewPromoCodeData('');
    }

    setPromoCodes(record.promoCodes);
  }, [record.promoCodes]);

  const onUploadFile = (file) => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = () => {
      if (typeof reader.result !== 'string') {
        return;
      }

      const promoArray = reader.result.split(/\r?\n/);
      const promoData = promoArray.map((code) => ({ data: code }));

      setPromoCodes([...promoData, ...promoCodes]);
      notify(`Промокоды успешно добавлены (${promoData.length} шт.)`, { type: 'success' });
    };

    reader.onerror = () => {
      if (!reader.error) {
        return;
      }

      notify(reader.error.message, { type: 'error' });
    };
  };

  const onRemoveItem = (index: number, id?: string) => (e) => {
    const updatedPromoCodes = [...promoCodes];

    if (!id) {
      updatedPromoCodes.splice(index, 1);
      setPromoCodes(updatedPromoCodes);
      return;
    }

    const removeIndex = id ? updatedPromoCodes.findIndex(({ id: findId }) => findId === id) : index;

    if (removeIndex === -1) {
      return;
    }

    updatedPromoCodes.splice(removeIndex, 1);
    setPromoCodes(updatedPromoCodes);
    setDeletedPromoCodeIds((prevPromoCodes) => [...prevPromoCodes, id]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isLoading || writeOffId === undefined) {
      return;
    }

    setIsLoading(true);

    const createdPromoCodes = [...promoCodes, { data: newPromoCodeData } as PromoCode].filter(
      ({ id, data }) => !id && data
    );

    const editedPromoCodes = promoCodes.filter(({ edited }) => edited).map(({ id, data }) => ({ id, data }));

    try {
      if (createdPromoCodes.length) {
        await createManyPromoCodes({ writeOffId, promocodes: createdPromoCodes });
      }

      if (editedPromoCodes.length) {
        await updateManyPromoCodes({ writeOffId, promocodes: editedPromoCodes });
      }

      if (deletedPromoCodeIds.length) {
        await deleteManyPromoCodes({ writeOffId, promocodes: deletedPromoCodeIds });
      }

      redirect(basePath);
      setIsLoading(false);
    } catch (err: any) {
      notify(err.status + ' ' + err.message, { type: 'error' });
      setIsLoading(false);
    }
  };

  const onChangeArrayInput = (index) => (e) => {
    let updatedPromoCodes = [...promoCodes];

    const currentPromoCode = updatedPromoCodes[index];
    currentPromoCode.data = e.target.value;

    if (currentPromoCode.id) {
      currentPromoCode.edited = true;
    }

    setPromoCodes(updatedPromoCodes);
  };

  const onClickAddNew = () => {
    if (!newPromoCodeData) {
      setNewPromoCodeData('');
      return;
    }

    setPromoCodes([{ data: newPromoCodeData }, ...promoCodes]);

    if (isPromoCodesType) {
      setNewPromoCodeData('');
    }
  };

  const totalPages = Math.ceil(promoCodes.length / PROMOCODES_PAGE_LENGTH);

  return (
    <>
      {isPromoCodesType && (
        <FileInput source="fileInput" label="Import from file" accept=".txt" multiple={false} parse={onUploadFile} />
      )}
      {((!isPromoCodesType && !promoCodes.length) || typeof newPromoCodeData !== 'undefined') && (
        <Input value={newPromoCodeData} onChange={(e) => setNewPromoCodeData(e.target.value)} />
      )}
      {((isPromoCodesType && newPromoCodeData !== '') || (!isPromoCodesType && promoCodes.length < 1)) && (
        <Fab
          color="primary"
          size="small"
          onClick={onClickAddNew}
          style={{ marginLeft: 10 }}
          disabled={isUnlimited || isDisabledByRole}
        >
          <Add />
        </Fab>
      )}

      {promoCodes
        .slice(page * PROMOCODES_PAGE_LENGTH, (page + 1) * PROMOCODES_PAGE_LENGTH)
        .map(({ data, id }, index) => (
          <PromoCodeLine key={index}>
            <Input value={data} onChange={onChangeArrayInput(index)} />
            <Fab disabled={isDisabledByRole} size="small" onClick={onRemoveItem(index, id)} tabIndex={-1}>
              <RemoveCircle color="error" />
            </Fab>
          </PromoCodeLine>
        ))}

      {totalPages > 1 && (
        <PaginationWrapper>
          <button
            disabled={page === 0}
            onClick={(e) => {
              e.preventDefault();
              setPage(page - 1);
            }}
          >
            {'<'} Previos page
          </button>
          <select onChange={({ target: { value } }) => setPage(Number(value))} value={page}>
            {new Array(totalPages).fill(0).map((_, page) => (
              <option key={`page ${page}`} value={page}>
                {page + 1}
              </option>
            ))}
          </select>

          <button
            disabled={page === totalPages - 1}
            onClick={(e) => {
              e.preventDefault();
              setPage(page + 1);
            }}
          >
            Next page {'>'}
          </button>
        </PaginationWrapper>
      )}

      <div>
        <br />
        <SubmitButton
          onClick={onSubmit}
          type="submit"
          color="primary"
          variant="contained"
          startIcon={<Save />}
          style={{ marginBottom: 10 }}
          disabled={isLoading || isDisabledByRole}
        >
          <>
            <span>Save Promo Codes</span>
          </>
        </SubmitButton>
      </div>
    </>
  );
};

const LotteryWinners = () => {
  const { lotteryWinners } = useRecordContext();

  return (
    <>
      <h4>Winners:</h4>
      {lotteryWinners !== undefined && lotteryWinners.length ? (
        <>
          <ArrayField source="lotteryWinners">
            <Datagrid>
              <DateField source="date" label="Date" />
              <TextField source="email" label="User email" />
              <TextField source="wallet" label="Wallet" />
              <TextField source="metamaskWalletAddress" label="Metamask Wallet Address" />
            </Datagrid>
          </ArrayField>
        </>
      ) : (
        <div>No winers yet...</div>
      )}
    </>
  );
};

const ClaimedTab = () => {
  const { claimed } = useRecordContext();

  return (
    <>
      <h4>Claimed Info:</h4>
      {claimed !== undefined && claimed.length ? (
        <>
          <ArrayField source="claimed" perPage={claimed.length}>
            <Datagrid>
              <TextField source="email" label="User email" />
              <TextField source="data" label="Code" />
              <DateField source="dateOfReceive" label="Date of receive" />
              <TextField source="wallet" label="Claimed Wallet" />
              <TextField source="metamaskWalletAddress" label="Metamask Wallet Address" />
            </Datagrid>
          </ArrayField>
        </>
      ) : (
        <div>No claimed info yet...</div>
      )}
    </>
  );
};

const TasksTab = () => {
  return (
    <>
      <ReferenceArrayInput
        source="tasksIds"
        reference="tasks"
        perPage={300}
        sort={{ field: 'name', order: SortingDirection.ASC }}
      >
        <AutocompleteArrayInput
          optionText={(record) => {
            const localeEntry = record.locales?.find((loc: any) => loc.locale === DEFAULT_LOCALE);
            return localeEntry?.name || '';
          }}
          fullWidth
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput reference="on-chain-task" source="onChainTaskIds" perPage={300}>
        <AutocompleteArrayInput
          label="On-Chain Tasks"
          optionText={(record) => {
            const localeEntry = record.locales?.find((loc: any) => loc.locale === DEFAULT_LOCALE);
            return localeEntry?.name || '';
          }}
          fullWidth
        />
      </ReferenceArrayInput>
    </>
  );
};

const Form = (props) => {
  const record = useRecordContext();
  const [type, setType] = useState(gamificationQuestTypes[0].id);

  const handleSetPromoCodesForm = (event) => setType(event.target.value);

  return (
    <TabbedForm {...props} toolbar={<WriteOffToolbar type={props.type} />}>
      <FormTab label="summary">
        <TextInput source="slug" label="Slug" required helperText={helperTexts.keyInputValidation} />

        <SelectInput
          source="questType"
          disabled={props.type === FormType.EDIT}
          choices={gamificationQuestTypes}
          defaultValue={gamificationQuestTypes[0].id}
          onChange={(event) => handleSetPromoCodesForm(event)}
        />

        {(props.type === FormType.EDIT && record.questType === QuestType.LOTTERY) ||
        (props.type === FormType.CREATE && type === QuestType.LOTTERY) ? (
          <LotteryForm questType={type} />
        ) : (
          <FcfsForm questType={type} />
        )}

        <NumberInput source="amount" label="Price" required min={0} />
        <BooleanInput source="isActive" label="Active" defaultValue={true} />
        <FunctionField
          label="Image"
          render={(record) => record.image && <img src={record.image} alt="image" style={{ maxHeight: '200px' }} />}
        />
        <ImageInput source="image" label="Upload Preview Image (262 x 262)" accept={imageInputAccept}>
          <ImageField source="src" title="Image" />
        </ImageInput>

        <FunctionField
          label="Logo"
          render={(record) => record.logo && <img src={record.logo} alt="logo" style={{ maxHeight: '46px' }} />}
        />
        <ImageInput source="logo" label="Upload Logo (148 x 148)" accept={imageInputAccept}>
          <ImageField source="logo" title="Logo" />
        </ImageInput>

        <FunctionField
          label="Banner"
          render={(record) => record.banner && <img src={record.banner} alt="banner" style={{ maxHeight: '280px' }} />}
        />
        <ImageInput source="banner" label="Upload Banner Image (915 x 346)" accept={imageInputAccept}>
          <ImageField source="banner" title="banner" />
        </ImageInput>

        <TranslatableInput langSource="locale">
          <TrimOnBlurInput source="title" label="Title" required />
          <TextInput
            source="short_description"
            label="Short Description"
            helperText="This text is used for preview in social media"
            sx={{ mb: 2 }}
          />
          <TinymceEditor source="description" plainTextSource="short_description" />
        </TranslatableInput>
      </FormTab>

      {props.type === FormType.EDIT && record.questType !== QuestType.LOTTERY && (
        <FormTab label="Promo Codes">
          <PromoCodesTab {...props} />
        </FormTab>
      )}

      {props.type === FormType.EDIT && (
        <FormTab label="Tasks">
          <TasksTab {...props} />
        </FormTab>
      )}

      {props.type === FormType.EDIT && (
        <FormTab label="Claimed">
          <ClaimedTab {...props} />
        </FormTab>
      )}
      {props.type === FormType.EDIT && (
        <FormTab label="Winners">
          <LotteryWinners {...props} />
        </FormTab>
      )}
    </TabbedForm>
  );
};

const transform = (form) => {
  form.locales.forEach((tr) =>
    tr.short_description ? (tr.short_description = tr.short_description.slice(0, 160)) : undefined
  );
  if (form.rewardType === RewardsType.CUSTOM && form.questType === QuestType.LOTTERY) {
    form.coinId = null;
  }
  return form;
};

const handleDublicatePriority = (notify) => (error) => {
  let errorMessage = error.toString();
  if (errorMessage.includes(ErrorTypes.DUBLICATE_PRIORITY)) {
    notify(`Raffle with such priority already exists.`, { type: 'error' });
  }
};

export const WriteOffCreate = (props) => {
  const notify = useNotify();
  return (
    <Create {...props} transform={transform} mutationOptions={{ onError: handleDublicatePriority(notify) }}>
      <Form {...props} type={FormType.CREATE} />
    </Create>
  );
};

const EditActions = () => {
  return (
    <TopToolbar>
      <AdminCreateBtn />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

const WriteOffTitle = () => {
  const record = useRecordContext();
  return <span>WriteOff '{getEnglishTitle(record)}'</span>;
};

export const WriteOffEdit = (props) => {
  const notify = useNotify();

  return (
    <Edit
      mutationOptions={{ onError: handleDublicatePriority(notify) }}
      actions={<EditActions />}
      title={<WriteOffTitle />}
      {...props}
      mutationMode="pessimistic"
      transform={transform}
    >
      <Form {...props} type={FormType.EDIT} />
    </Edit>
  );
};

export const WriteOffsList = (props) => {
  const isDisabled = getRole() === UserRole.ACCOUNT_MANAGER;

  return (
    <List {...props} sort={{ field: 'createdAt', order: SortingDirection.DESC }} perPage={50} exporter={false}>
      <Datagrid bulkActionButtons={false}>
        <FunctionField label="Name" render={(record) => <span>{getEnglishTitle(record)}</span>} />

        <DateField source="createdAt" showTime locales="ru" />
        <BooleanField source="isActive" />
        <TextField source="amount" />
        <TextField source="claimedPromoCodes" label="Claimed" />
        <TextField source="totalPromoCodes" label="Initial" sortable={false} />
        <EditButton disabled={isDisabled} />
        <AdminDeleteButton disabled={isDisabled} />
      </Datagrid>
    </List>
  );
};
